<template>
  <div>
    <div class="crumbs">
      <router-link to="/a-company/company">首页</router-link>
      <label>></label>
      <a href="###">企业详情</a>
      <label>></label>
      <a href="###">编辑</a>
    </div>

    <div class="edit-border-box">
      <div class="edit-title">编辑企业信息</div>
      <el-form
        class="edit-form"
        size="small"
        label-width="120px"
        label-position="left"
      >
        <input type="file" ref="upload" @change="onSelectFile" hidden />
        <template v-if="formStep == 1">
          <div>请填写以下信息</div>
          <el-form-item style="margin-top: 50px" label="企业Logo" size="small">
            <div @click="selectFile('logoF')">
              <img
                style="width: 210px; height: 210px"
                v-if="files.logoF.blob || form.logo"
                :src="files.logoF.blob || form.logo"
                alt=""
              />
              <template v-else>
                <i class="el-icon-plus"></i>
                <span>上传图片</span>
              </template>
            </div>
          </el-form-item>
          <el-form-item label="公司名称">
            <el-input
              v-model="form.name"
              placeholder="请填写公司名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="公司代码">
            <el-input
              disabled
              v-model="form.code"
              placeholder="请填写公司代码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码">
            <el-input
              disabled
              v-model="form.bindPhone"
              placeholder="请填写手机号码"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="年营业额">
            <el-input v-model="form.salse" placeholder="0.00" clearable>
              <span slot="suffix"> 万元</span>
            </el-input>
          </el-form-item>
          <el-form-item label="年采购额">
            <el-input v-model="form.purchase" placeholder="0.00" clearable>
              <span slot="suffix"> 万元</span>
            </el-input>
          </el-form-item>
          <el-form-item label="年投标规模">
            <el-input
              v-model="form.bidAmount"
              placeholder="请填写年投标规模"
              clearable
            >
              <span slot="suffix"> 万元</span>
            </el-input>
          </el-form-item>
        </template>

        <template v-if="formStep == 2">
          <div style="margin: 50px 0">请完善公司信息</div>
          <el-form-item label="身份证正反面">
            <el-upload
              class="file-item"
              action=""
              :auto-upload="false"
              :on-change="fileChange.bind(this, 'idCardF')"
              :on-remove="fileRemove.bind(this, 'idCardF')"
              :limit="1"
              accept=".pdf"
            >
              <el-button slot="trigger" size="small" type="primary"
                >上传</el-button
              >
            </el-upload>
            <div v-if="form_data.idCardF.length > 0" class="show">
              <p
                @click="
                  handleClick('idCardF', '身份证正反面', form_data.idCardF)
                "
              >
                预览
              </p>
            </div>
          </el-form-item>
          <el-form-item label="公司授权委托书">
            <el-upload
              action=""
              class="file-item"
              :auto-upload="false"
              :on-change="fileChange.bind(this, 'certF')"
              :on-remove="fileRemove.bind(this, 'certF')"
              :limit="1"
              accept=".pdf"
            >
              <el-button slot="trigger" size="small" type="primary"
                >上传</el-button
              >
            </el-upload>
            <div v-if="this.form_data.certF.length > 0">
              <p
                class="show"
                @click="handleClick('certF', '公司授权委托书', form_data.certF)"
              >
                预览
              </p>
            </div>
          </el-form-item>
        </template>
      </el-form>

      <el-dialog
        :title="eldialogTitle"
        :visible.sync="isViewPdf20"
        :fullscreen="true"
      >
        <iframe
          :src="eldialogUrl"
          frameborder="0"
          style="width: 100%; height: 85vh"
        ></iframe>
      </el-dialog>
      <div class="action-group">
        <el-button
          class="action-btn-item"
          size="small"
          type="primary"
          :disabled="formStep == 1"
          @click="formStep--"
          >上一步</el-button
        >
        <el-button
          class="action-btn-item"
          size="small"
          type="primary"
          @click="formStep++"
          v-if="formStep != 2"
          >下一步</el-button
        >

        <el-button
          class="action-btn-item"
          size="small"
          type="primary"
          @click="submitEdit"
          v-if="formStep == 2"
          >提交</el-button
        >

        <el-button class="action-btn-item" size="small" @click="$router.back()"
          >返回</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formStep: 1,
      isViewPdf20: false,
      eldialogTitle: "",
      eldialogUrl: "",
      fileList: "",

      form: {},
      form_data: {},

      uploadKey: "",
      files: {
        logoF: {
          file: null,
          blob: null,
        },
        idCardF: {
          file: null,
          blob: null,
        },
        idCardF2: {
          file: null,
          blob: null,
        },
        certF: {
          file: null,
          blob: null,
        },
      },
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let code = this.$route.params.code;
      this.$axios.get(`/member/selectMemberDetail/${code}`).then((res) => {
        if (res.state == 200) {
          this.form = res.data;
          this.form_data = {
            idCardF: [res.data.id1],
            certF: [res.data.certFile],
            idCardFUrl: res.data.id1,
            certFUrl: res.data.certFile,
          };
        } else {
          this.$message.error(res.message);
        }
      });
    },
    selectFile(key) {
      this.uploadKey = key;
      this.$refs.upload.click();
    },
    onSelectFile(e) {
      let files = e.target.files;
      if (files.length == 0) return;

      this.files[this.uploadKey] = {
        file: files[0],
        blob: URL.createObjectURL(files[0]),
      };

      e.target.value = "";
    },
    fileChange(key, file, fileList) {
      //认证文件只能上传pdf文件
      this.fileList = fileList;
      var urlKey = key + "Url";
      this.form_data[key] = [];
      this.form_data[urlKey] = "";
      var fileName = file.name;

      var fileNameIndex = fileName.lastIndexOf(".");
      var newfileName = fileName.substring(fileNameIndex, fileName.length);
      if (newfileName != ".pdf") {
        this.$message.error("上传文件只能是 PDF 格式!");
        fileList[0] = "";
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file.raw);
        let URL = window.URL || window.webkitURL;
        let url = URL.createObjectURL(file.raw);
        this.form_data[key] = [url];
        this.form_data[urlKey] = url;
      }
    },
    fileRemove(key, file, fileList) {
      var urlKey = key + "Url";
      this.form_data[key] = [];
      this.form_data[urlKey] = "";
    },
    handleClick(item, title, file) {
      //预览文件
      this.isViewPdf20 = false;
      let url = item + "Url";
      this.isViewPdf20 = true;
      this.eldialogTitle = title;
      this.eldialogUrl = this.form_data[url];
    },
    submitEdit() {
      if (this.form.name == "") {
        alert("请输入公司名称");
        return;
      }
      if (this.form.salse == "") {
        alert("请输入年销售额");
        return;
      }
      if (this.form.purchase == "") {
        alert("请输入采购额");
        return;
      }
      if (this.form.bidAmount == "") {
        alert("请输入年投标规模");
        return;
      }

      if (this.form_data.idCardF.length === 0) {
        alert("请上传身份证正反面文件");
        return;
      }
      if (this.form_data.certF.length == 0) {
        alert("请上传公司授权委托书");
        return;
      }

      let data = {
        id: this.form.id,
        name: this.form.name,
        code: this.form.code,
        bindPhone: this.form.bindPhone,

        salse: this.form.salse,
        purchase: this.form.purchase,
        bidAmount: this.form.bidAmount,
      };
      if (this.files.logoF.file) {
        data.logoF = this.files.logoF.file;
      }
      if (this.form_data.idCardF.length) {
        data.id1 = this.form_data.idCardF[0];
      }
      if (this.form_data.certF.length) {
        data.certFile = this.form_data.certF[0];
      }
      var formdata = new FormData();
      Object.keys(data).forEach((key) => {
        formdata.set(key, data[key]);
      });

      this.$axios.post("/member/updataMember", formdata).then((res) => {
        if (res.state == 200) {
          this.$message.success(res.message);
          this.$router.push("/a-company/company-preserve");
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.edit-border-box {
  border: 1px solid #ededed;
  padding: 64px;
  box-sizing: border-box;
  margin-bottom: 20px;
  .edit-title {
    font-size: 28px;
    text-align: center;
    padding-bottom: 63px;
  }
  .edit-form {
    width: 325px;

    .company-logo {
      width: 150px;
      height: 150px;
      background: #ffffff;
      border: 1px solid #dadada;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #999;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      i {
        font-size: 25px;
      }
    }
  }
  .action-group {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    .action-btn-item {
      width: 151px;
    }
  }

  .idcard {
    width: 240px;
    height: 151px;
    background: #ffffff;
    border-radius: 4px;
    border: 2px solid #e6ebf4;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .idcard1 {
  }
  .idcard2 {
    margin-top: 24px;
  }
  .auth-file {
    width: 206px;
    height: 240px;
    background: #ffffff;
    border: 1px solid #dadada;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    .auth-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.el-form-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-around;
  width: 500px;

  .show {
    position: absolute;
    right: -70px;
    top: 2px;
    width: 56px;
    height: 30px;
    text-align: center;
    line-height: 32px;
    color: #fff;
    font-size: 12px;
    background-color: #3883ff;
    cursor: pointer;
  }
  a {
    position: absolute;
    right: -230px;
    top: 2px;
  }

  .file-item {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .el-upload-list {
      width: 150px;
    }
    .el-upload {
      margin-left: 20px;
    }
  }
}
</style>